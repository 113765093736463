import React, { useState } from "react";
import {
  DragDropFileUpload,
  FormSelect,
  HeaderNavbar,
  PageHeader,
} from "@allsynx/components";
import TbhLogo from "./resources/tbhlogo.png";
import "./App.css";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
// import useAuth from "./hooks/useAuth";

function App() {
  // useAuth();

  const [uploadedFile, setUploadedFile] = useState<File>({} as File);
  const [showModal, setShowModal] = useState(false);
  const [hasSavedFile, setHasSavedFile] = useState(false);
  const [fileValidity, setFileValidity] =
    useState<FileValidity>(initialFileValidity);
  const [employeeDataFile, setEmployeeDataFile] = useState<FileData>(
    {} as FileData
  );
  const [fileName, setFileName] = useState("");
  const [isFileRead, setIsFileRead] = useState(false);
  const [isFileDropped, setIsFileDropped] = useState(false);

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    const file = e.dataTransfer.files[0];
    setUploadedFile(file);

    if (hasSavedFile) {
      setShowModal(true);
    } else {
      handleUploadedFiles(file);
    }
  };

  const handleInputUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    e.stopPropagation();

    const file = e.currentTarget.files && e.currentTarget.files[0];

    file && setUploadedFile(file);

    if (hasSavedFile) {
      setShowModal(true);
    } else {
      file && handleUploadedFiles(file);
    }
  };

  const handleUploadedFiles = async (file: File) => {
    setFileValidity(initialFileValidity);
    setShowModal(false);

    await Promise.all([
      readUploadedFiles(file).then((result) => {
        setEmployeeDataFile({
          fileName: file.name,
          fileInBase64: result.replace(
            "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,",
            ""
          ),
        });
        setFileName(file.name);
        setIsFileDropped(true);
      }),
    ]);
  };

  const deleteFile = () => {
    // deleteEmployeeData();
    setFileName("");
    setIsFileRead(false);
    setFileValidity(initialFileValidity);
    setEmployeeDataFile({} as FileData);
  };

  console.log(!![uploadedFile]);
  console.log(!!uploadedFile);
  return (
    <>
      <HeaderNavbar imgSrc={TbhLogo} rightElement={<a>Logout</a>} />
      <Container id="mainContainer">
        <PageHeader>PrimeCare Quote Tool</PageHeader>
        <br />
        <Form>
          <Row>
            <Col md={5}>
              <FormSelect
                label="Product"
                onChange={() => console.log("wut")}
                value="heyy"
                title="yoo"
              >
                <option>1</option>
              </FormSelect>
              <DragDropFileUpload
                id="employeeDataUpload"
                onDrop={(e) => handleDrop(e)}
                validFileType="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                fileInputControlId="fileUpload"
                onFormFileChange={(e) => handleInputUpload(e)}
                uploadedFiles={
                  Object.keys(uploadedFile).length > 0
                    ? ([uploadedFile] as unknown as File[])
                    : null
                }
                deleteFile={deleteFile}
              ></DragDropFileUpload>
              {
                <div
                  className={
                    "file-instructions" +
                    (fileValidity.isExists &&
                    fileValidity.isValidData &&
                    fileValidity.isValidType
                      ? ""
                      : " invalid")
                  }
                >
                  <div>
                    A{" "}
                    <span
                      className={fileValidity.isValidData ? "" : "highlight"}
                    >
                      valid
                    </span>{" "}
                    <span
                      className={fileValidity.isValidType ? "" : "highlight"}
                    >
                      .xlsx
                    </span>{" "}
                    Employee Data File must be{" "}
                    <span className={fileValidity.isExists ? "" : "highlight"}>
                      uploaded
                    </span>{" "}
                    before you can continue.{" "}
                  </div>
                </div>
              }
            </Col>
          </Row>
        </Form>
      </Container>
      <Button
        id="backButton"
        className="back-button"
        onClick={() => window.history.back()}
      />
    </>
  );
}

export default App;

export type FileValidity = {
  isExists: boolean;
  isValidData: boolean;
  isValidType: boolean;
};

export const initialFileValidity: FileValidity = {
  isExists: true,
  isValidData: true,
  isValidType: true,
};

export const readUploadedFiles = (file: File) => {
  const reader = new FileReader();

  return new Promise<string>((resolve, reject) => {
    reader.onerror = () => {
      reader.abort();
      reject(new DOMException("Error: Problem parsing uploaded file."));
    };

    reader.onload = async () => {
      reader.result &&
        typeof reader.result === "string" &&
        resolve(reader.result);
    };

    reader.readAsDataURL(file);
  });
};

type FileData = {
  fileName: string;
  fileInBase64: string;
};
